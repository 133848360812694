export default {
  colors: {
    primary: '#d61537',
    background: '#2E3440',
    shape: `#355561`,
    title: `#d61537`,
    text: `#fff`,
    components: {
      blockquote: {
        background: `#099`,
        text: `#2d3748`,
      },
    },
  },
};
